@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Jost';
        font-weight: 400;
        src: local('Jost'), url(./fonts/jost/OpenType/Jost-400-Book.otf) format("opentype");
    }
    @font-face {
        font-family: 'Jost';
        font-weight: 500;
        src: local('Jost'), url(./fonts/jost/OpenType/Jost-500-Medium.otf) format("opentype");
    }
    @font-face {
        font-family: 'Jost';
        font-weight: 600;
        src: local('Jost'), url(./fonts/jost/OpenType/Jost-600-Semi.otf) format("opentype");
    }
    @font-face {
        font-family: 'Jost';
        font-weight: 700;
        src: local('Jost'), url(./fonts/jost/OpenType/Jost-700-Bold.otf) format("opentype");
    }
}

/* https://github.com/KillerCodeMonkey/ngx-quill/issues/147 */
.ql-container {
    resize: both;
    overflow-y: scroll;
}
